import React, { Component } from 'react';
import Editor from './Editor';
import Header from './HeaderNav';

class Main extends Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <div>
        <Header />
        <div style={{ marginTop: '3.5rem' }}>
          <Editor />
        </div>
      </div>
    );
  }
}

export default Main;
