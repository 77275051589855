import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from './Main';
import Error from './Error';

function App() {
  return (
    <div className="container-lg">
      <Switch>
        <Route path="/" component={Main} exact />
        <Route path="/t" component={Main} />
        <Route component={Error} />
      </Switch>
    </div>
  );
}

export default App;
