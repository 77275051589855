import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import { TranscriptEditor } from '@jodge/react-transcript-editor';

const API_BASE_URL = 'https://smarttranscribers.com/api';
// const API_BASE_URL = 'http://localhost:5050/api';

const save = (token, data) => {
  fetch(`${API_BASE_URL}/v1/transcripts`, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `JWT ${token}`
    }
  }).then(() => console.log('saved'));
};

const fn = debounce(save, 10000);

class Editor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mediaType: 'video',
      sttJsonType: 'draftjs',
      isEditable: true,
      isLoading: true
    };

    this.getTranscript = this.getTranscript.bind(this);
    this.handleAutoSaveChanges = this.handleAutoSaveChanges.bind(this);
    this.handleAnalyticsEvents = this.handleAnalyticsEvents.bind(this);
  }

  async componentDidMount() {
    await this.getTranscript();
    document.title = this.state.title || 'Transcript Editor';
  }

  // eslint-disable-next-line class-methods-use-this
  handleAnalyticsEvents() {
  }

  async handleAutoSaveChanges(contentState) {
    const token = this.getToken();
    if (token) {
      const data = {
        name: this.state.title,
        script: JSON.stringify(contentState.data)
      };
      fn(token, data);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getToken() {
    const token = window.location.pathname.split('/').pop();
    return token;
  }

  // eslint-disable-next-line class-methods-use-this
  async getTranscript() {
    const token = this.getToken();
    if (token) {
      const response = await fetch(`${API_BASE_URL}/v1/transcripts`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `JWT ${token}`
        }
      });
      const { script, name } = await response.json();
      const mediaUrl = `${API_BASE_URL}/v1/transcripts/stream?token=${token}`;
      const transcriptData = JSON.parse(script);
      this.setState({
        transcriptData,
        fileName: name,
        title: name,
        mediaUrl,
        isLoading: false
      });
    }
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center">
          <h3 className="font-italic mt-5 text-muted">
            Loading...
          </h3>
        </div>
      );
    }
    return (
      <TranscriptEditor
        transcriptData={this.state.transcriptData}
        mediaUrl={this.state.mediaUrl}
        autoSaveContentType={this.state.sttJsonType}
        isEditable={this.state.isEditable}
        spellCheck={true}
        sttJsonType={this.state.sttJsonType}
        fileName={this.state.fileName}
        title={this.state.title}
        mediaType={this.state.mediaType}
        handleAutoSaveChanges={this.handleAutoSaveChanges}
        handleAnalyticsEvents={this.handleAnalyticsEvents}
      />
    );
  }
}

export default Editor;
