import React, { Component } from 'react';

class Error extends Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <h1>
        Oops! an error occurred
      </h1>
    );
  }
}

export default Error;
