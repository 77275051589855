import React, { Component } from 'react';

class HeaderNav extends Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <nav className="navbar navbar-dark fixed-top bg-primary">
        <div className="container-fluid flex">
          <a
            className="navbar-brand"
            href='/'
            style={{ fontFamily: 'Kaushan Script' }}>
            <img
              src="/st.png"
              alt="logo" />
              Smart Transcribers
          </a>
        </div>
      </nav>
    );
  }
}

export default HeaderNav;
